type Props = {
  key?: string;
  className: string;
};

/**
 * This component wraps the FontAwesomeIcon inside a span.
 * FontAwesome add icon at runtime, we need this container
 * to fix the issue with React trying to add/remove conditionally nodes.
 */
const FontAwesomeIcon = ({ key, className }: Props) => {
  return (
    <span role="presentation">
      <i key={key} className={className} />
    </span>
  );
};

export { FontAwesomeIcon };
