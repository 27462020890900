import { forwardRef } from 'react';

import { CloseSVG } from '../../Icons';
import { CustomInput } from '../types';
import styles from './index.module.scss';

type Props = CustomInput & {
  clearButtonAriaLabel: string;
  onClear: () => void;
};

const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ value, clearButtonAriaLabel, onClear, ...props }, ref) => {
    const clearButtonVisible = !!value;
    return (
      <div className={styles.container}>
        <input ref={ref} type="search" value={value} {...props} />
        {clearButtonAriaLabel && (
          <button
            className="text-gray-500"
            onClick={onClear}
            aria-label={clearButtonAriaLabel}
            data-visible={clearButtonVisible}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClear?.();
              }
            }}
          >
            <CloseSVG tabIndex={-1} />
          </button>
        )}
      </div>
    );
  },
);

export { SearchInput };
