import { HttpCode } from 'core/constants/app-constants';
import { formatLog } from 'core/utils/logger-utils';
import { getPlan } from './plan-service';
import { processRunningInfo } from '../ui/plan-ui-data-service';

type PollerParams = {
  planId: string;
  emplid: string;
  onSuccess: (data: {
    status: number;
    plan: API.PlanData.Plan;
    setup: API.PlanData.ConfigPayload;
  }) => void;
  onError: (error: any) => void;
  onCompleted?: () => void;
};

/** Throw this error if the Plan creation/update it takes too long */
export class PollerTimeOutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PollerTimeOutError';
  }
}

let timerId: NodeJS.Timer;

function pollerLog(action: string, message?: string) {
  return formatLog({
    scope: 'Plan Poller',
    action,
    message,
  });
}

const stopPlanPoller = () => {
  clearTimeout(timerId);
};

async function startPlanPoller({
  planId,
  emplid,
  onSuccess = (_data) => {},
  onError = (_error) => {},
  onCompleted = () => {},
}: PollerParams): Promise<void> {
  const TIME_OUT = 10_000;

  console.log(...pollerLog('Start'));

  return new Promise((resolve, reject) => {
    async function runPlanPoller() {
      try {
        console.log(...pollerLog('Pending', 'Check audit plan'));

        const res = await getPlan(emplid, planId);
        const { status, plan, lastModified } = res;
        const buildStatus = plan?.buildStatus;
        // ===========================================
        // Check if the plan is running for too long
        // ===========================================
        if (!buildStatus) {
          const runningInfo = processRunningInfo({
            buildStatus: null,
            lastModified: lastModified!,
          });

          if (runningInfo.isLongRunning) {
            throw new PollerTimeOutError(
              `Plan has been running for too long.
              Last modified: ${runningInfo.lastModified}.
              An server error might have occurred.`,
            );
          }
        }
        // ===========================================
        // Check if the plan has an error
        // ===========================================
        if (buildStatus === 'ERROR') {
          throw new Error(`buildStatus: ${buildStatus}`);
        }
        // ===========================================

        if (
          status === HttpCode.IN_PROGRESS_CODE ||
          buildStatus !== 'COMPLETE'
        ) {
          console.log(...pollerLog('Pending', `Audit still in progress`));
          timerId = setTimeout(() => runPlanPoller(), TIME_OUT);
        } else if (
          status === HttpCode.SUCCESS_CODE &&
          buildStatus === 'COMPLETE'
        ) {
          console.log(...pollerLog('Completed', 'Plan was found'));
          stopPlanPoller();
          onSuccess({
            status,
            plan: res.plan!,
            setup: res.setup!,
          });
          resolve();
          onCompleted();
        } else {
          console.log(...pollerLog('Stop', `Status code "${status}" received`));
          stopPlanPoller();
          reject({
            status,
          });
        }
      } catch (error) {
        console.log(...pollerLog('Error', (error as Error).message));
        stopPlanPoller();
        onError(error);
        reject(error);
      }
    }

    runPlanPoller();
  });
}

export { startPlanPoller, stopPlanPoller };
