import { CourseLabels } from 'core/constants/app-constants';

/**
 * Returns either a string which represents a range of values `${minValue}-${maxValue}`
 * or `${minValue}` or `${maxValue}` or `${defaultResponse}`
 */
function parseMinMaxValues(
  minValue: number | undefined,
  maxValue: number | undefined,
  defaultResponse: string = '0',
) {
  if (
    minValue !== undefined &&
    maxValue !== undefined &&
    minValue !== maxValue
  ) {
    return `${minValue}-${maxValue}`;
  }

  return `${minValue || maxValue || defaultResponse}`;
}

function extractUserId(email: string, asurite?: string) {
  return asurite || email?.split('@').shift() || '';
}

function parsePlanId(planId: string) {
  return `${planId.replaceAll('PLAN#', '')}`;
}

function parseBoolToYesNo(value?: boolean, defaultResponse = 'No') {
  if (value === undefined || value === null) {
    return defaultResponse;
  }

  if (value) {
    return 'Yes';
  }
  return 'No';
}

/**
 * Convert 5 digits term to 4 digits format
 * @example const term = to4digitsTerm("20217")
 * console.log(term); // 2217
 * @param sTerm
 */
function toTerm4Digits(sTerm: string) {
  if (sTerm.length === 5) {
    return sTerm.slice(0, 1) + sTerm.slice(2);
  }
  return sTerm;
}

function isMaxCreditWarning(
  reason: API.PlanData.PlanValidationError['reason'],
) {
  return (
    reason === 'TERM_CREDIT_HOURS_EXCEED' ||
    reason === 'SESSION_CREDIT_HOURS_EXCEED'
  );
}

/**
 * This function implements these requirements;
 * https://asudev.jira.com/wiki/spaces/CMIDP/pages/3885400206/Plan+Modifications
 */
function buttonVisibility({
  status,
  isActivePlan,
  courseLabel,
  isFromCourseList,
}: {
  status: API.PlanData.ClassStatus;
  isActivePlan: boolean;
  courseLabel: string | undefined;
  isFromCourseList?: boolean | undefined;
}) {
  let chooseCourseVisible = true;
  let removeCourseVisible = true;
  let saveCourseVisible = true;
  let closeCourseVisible = false;
  let formDataReadonly = false;

  if (status === 'planned') {
    // [Active & What-if Plans]
    if (courseLabel === CourseLabels.SINGLE_COURSE_OPTION) {
      chooseCourseVisible = false;
      removeCourseVisible = false;
    } else if (
      courseLabel === CourseLabels.MULTIPLE_COURSE_OPTIONS ||
      courseLabel === CourseLabels.ELECTIVE ||
      courseLabel === CourseLabels.UNUSED
    ) {
      chooseCourseVisible = true;
      removeCourseVisible = true;
    }
  }
  // Enrolled course. 'in-progress' / 'registered'(AKA not-started)
  else if (status === 'in-progress' || status === 'not-started') {
    if (isActivePlan) {
      saveCourseVisible = false;
      formDataReadonly = true;
      chooseCourseVisible = false;
      removeCourseVisible = false;
    }
    // [What-if Plan]
    else {
      if (courseLabel === CourseLabels.SINGLE_COURSE_OPTION) {
        chooseCourseVisible = false;
        removeCourseVisible = true;
      } else if (
        courseLabel === CourseLabels.MULTIPLE_COURSE_OPTIONS ||
        courseLabel === CourseLabels.ELECTIVE ||
        courseLabel === CourseLabels.UNUSED
      ) {
        chooseCourseVisible = true;
        removeCourseVisible = true;
      }
    }
  } /* status === 'completed' */ else {
    chooseCourseVisible = false;
    removeCourseVisible = false;
    saveCourseVisible = false;
    closeCourseVisible = true;
  }

  if (isFromCourseList) {
    removeCourseVisible = false;
  }
  // handle cases for old plans created prior `courseLabel` BE implementation
  if (!courseLabel) {
    chooseCourseVisible = false;
    removeCourseVisible = false;
  }

  return {
    chooseCourseVisible,
    removeCourseVisible,
    saveCourseVisible,
    closeCourseVisible,
    formDataReadonly,
  };
}

export {
  buttonVisibility,
  extractUserId,
  isMaxCreditWarning,
  parseBoolToYesNo,
  parseMinMaxValues,
  parsePlanId,
  toTerm4Digits,
};
