import React, { FC } from 'react';

import { SpinnerLoader } from '..';

type Props = {
  className?: string;
  htmlTag?: keyof JSX.IntrinsicElements;
  loaderMessage?: string | null;
  hasShadow?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

const Box: FC<Props> = ({
  className,
  htmlTag = 'div',
  loaderMessage,
  hasShadow = true,
  children,
}) => {
  const Container = htmlTag;

  return (
    <Container
      className={`${className} relative ${hasShadow ? 'shadow-md' : ''}`}
    >
      {loaderMessage && (
        <SpinnerLoader
          spinnerMessage={loaderMessage}
          spinnerType="circle"
          size="20px"
          attachContainer="element"
          fullOpacity
        />
      )}

      {loaderMessage ? <div className="invisible">{children}</div> : children}
    </Container>
  );
};

export { Box };
