import React, { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  baseClassName?: string;
} & React.ComponentProps<'button'>;

const BaseButton: FC<Props> = React.forwardRef(
  (
    {
      children,
      className = '',
      baseClassName = 'inline-flex items-center justify-center gap-2 hover:scale-105',
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={`${styles.container} ${baseClassName} ${className}`}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </button>
  ),
);

export { BaseButton };
