import axios from 'axios';
import { httpFetcher, HttpFetchParam } from './http-utils';

const DPL_API = axios.create({
  baseURL: 'https://api.myasuplat-dpl.asu.edu',
});

const cachedData: Record<string, any> = {};

const fetchData = async <TDataResponse>(
  param: HttpFetchParam<TDataResponse>,
) => {
  return httpFetcher<TDataResponse>(DPL_API, cachedData, param);
};

export { fetchData };
